<!-- eslint-disable import/no-cycle -->
<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">Kanal Dagang</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-text class="mb-2">
            Untuk memulai, silahkan masukkan email dan password Anda.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Lupa Password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Lupa Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Ingat saya
                </b-form-checkbox>
              </b-form-group>

              <GoogleCaptcha
                ref="captcha"
                sitekey="6Ldsx74pAAAAAAPIsmGR4KJX6GGmGNmOPQDEpk6r"
                action="userlogin"
                @google_captcha_token="updateToken"
              ></GoogleCaptcha>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Masuk
              </b-button>

              <!-- <p class="text-center pt-1">
                -- atau --
              </p>

              <b-form-group>
                <a
                  class="btn-block text-uppercase btn-outline btn-google btn-ggl"
                  :href="`${apiURL}/auth/google`"
                >
                  <img
                    src="https://cdn.cdnlogo.com/logos/g/82/google-g-2015.svg"
                    width="20"
                    height="20"
                    class="mr-2"
                  >
                  Login dengan Google
                </a>
              </b-form-group> -->
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>Belum punya akun? </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;Buat akun sekarang</span>
            </b-link>
          </b-card-text> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue';
import jwt from 'jsonwebtoken';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
// eslint-disable-next-line import/no-cycle
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import GoogleCaptcha from 'vue2-recaptchav3';

// auth
import useJwt from '@/auth/jwt/useJwt';
import { getHomeRouteForLoggedInUser } from '@/auth/utils';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    GoogleCaptcha,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: localStorage.getItem('status') || false,
      password: localStorage.getItem('password') || '',
      userEmail: localStorage.getItem('userEmail') || '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      required,
      email,
      apiURL: process.env.VUE_APP_API_URL,
      googleToken: '',
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  metaInfo() {
    return {
      title: 'Kanal Dagang',
      titleTemplate: '%s - Masuk',
    };
  },
  mounted() {
    if (this.$route.query.token && this.$route.query.refreshToken) {
      useJwt.setToken(this.$route.query.token);
      useJwt.setRefreshToken(this.$route.query.refreshToken);

      const user = jwt.decode(
        localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName),
      );

      this.$ability.update(user.ability);

      this.$router
        .replace(getHomeRouteForLoggedInUser(user.data.role))
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Welcome ${user.data.name}`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `You have successfully logged in as ${user.data.name}. Now you can start to explore!`,
            },
          });
        });
    }
  },
  methods: {
    updateToken(payload) {
      this.googleToken = payload.response_token;
    },

    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        const passwordEncrypted = btoa(this.password); // Menggunakan library CryptoJS untuk enkripsi satu arah
        if (success) {
          if (this.status) {
            // set login data to local storage
            localStorage.setItem('userEmail', this.userEmail);
            localStorage.setItem('password', passwordEncrypted);
            localStorage.setItem('status', this.status);
          } else {
            // remove login data from local storage
            localStorage.removeItem('userEmail');
            localStorage.removeItem('password');
            localStorage.removeItem('status');
          }

          this.$refs.captcha.generateManualToken().then(() => {
            useJwt
              .login({
                email: this.userEmail,
                password: passwordEncrypted,
                'g-recaptcha-response': this.googleToken,
              })
              .then(response => {
                if (response) {
                  if (response.data.status === true) {
                    useJwt.setToken(response.data.accessToken);
                    useJwt.setRefreshToken(response.data.refreshToken);

                    const user = jwt.decode(
                      localStorage.getItem(
                        useJwt.jwtConfig.storageTokenKeyName,
                      ),
                    );

                    this.$ability.update(user.ability);

                    this.$router
                      .replace(getHomeRouteForLoggedInUser(user.data.role))
                      .then(() => {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: `Welcome ${user.data.name}`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: `You have successfully logged in as ${user.data.name}. Now you can start to explore!`,
                          },
                        });
                      });
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Login Failed',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: response.data.message,
                      },
                    });
                  }
                }
              });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.btn-google {
  color: #545454 !important;
  background-color: #ffffff !important;
  box-shadow: 0 1px 2px 1px #ddd !important;
}

.btn-ggl {
  border-radius: 2px !important;
  text-transform: capitalize !important;
  font-size: 15px !important;
  padding: 10px 19px !important;
  cursor: pointer;
}
</style>
